<template>
  <modal-layout @close="$emit('close')" title="Пользовательское соглашение">
    <div class="p-3">
      <p>
        Подписанием настоящего Заявления-анкеты даю АО «Страховая компания «Халык», БИН
        981040001082, (далее – Страховщик) согласие на сбор и обработку своих персональных данных,
        которые могут содержать (включая, но не ограничиваясь) сведения, необходимые для
        идентификации/аутентификации/авторизации: фамилия, имя, отчество (если оно указано в
        документе, удостоверяющем личность), гражданство, данные документа, удостоверяющего
        личность, индивидуальный идентификационный номер, дата и место рождения, место жительства,
        юридический адрес, номер телефона, адрес электронной почты, изображение, запись голоса.
      </p>
      <p>
        Целью сбора и обработки персональных данных является исполнение Страховщиком своих
        обязательств по договору о страховании.
        Также для целей улучшения качества обслуживания и проведения маркетинговых программ
        настоящим даю свое согласие на получение информации об услугах Страховщика, на проведение
        опросов в виде телефонных звонков, sms-сообщений, push-сообщений и электронных писем на
        указанный мною номер телефона и/или адрес электронной почты.
        Я даю согласие Страховщику на передачу, в том числе трансграничную, своих персональных
        данных третьим лицам (в том числе аффилированным лицам Компании, профессиональным
        участникам страхового рынка и иным лицам, участвующим в рамках исполнения условий
        договора страхования и/или маркетинговых услуг).
        Настоящее согласие выдано на срок до достижения целей, определенных Страховщиком, и
        действует до момента его отзыва, если иное не предусмотрено законодательством Республики
        Казахстан.
        Страховщик вправе проверить достоверность предоставленных персональных данных, в том
        числе с использованием других доступных источников.
        Настоящим заявляю, что сведения, указанные в Заявлении верны и не содержат ложной
        информации или каких-либо неточностей, и вся существенная информация в соответствии с
        требованием Страховщика мною предоставлена. Мне также известно о последствиях за сообщение
        ложных сведений об объекте страхования, страховом риске, страховом случае и его последствиях.
        Также подтверждаю, что данное Заявление будет служить основанием для заключения
        договора страхования.
        С Правилами АО «Страховая компания «Халык» ознакомлен(а) и их копию посредством сайта
        Страховщика получил(а).
        Уведомление. Страховщик оставляет за собой право расторгнуть договор страхования и/или
        отказать в осуществлении страховой выплаты в случае, если Страхователь настоящим привел
        ложные сведения либо утаил какую бы то ни было существенную информацию в целях
        заключения договора страхования, а также по основаниям, предусмотренным Законом РК «О
        противодействии легализации (отмыванию) доходов, полученных преступным путем, и
        финансированию терроризма».
      </p>

      С Правилами страхования АО «Страховая компания «Халык» ознакомлен(а) и их копию получил(а).


      Уведомление. Страховщик оставляет за собой право расторгнуть полис и/или отказать в осуществлении страховой выплаты в случае, если Страхователь настоящим привел ложные сведения либо утаил какую бы то ни было существенную информацию в целях заключения полис, а также по основаниям, предусмотренным Законом РК «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма».
    </div>
    <button class="bottom-page-btn primary-btn" @click="$emit('close')">Закрыть</button>
  </modal-layout>
</template>

<script>
export default {
  name: "TermsModal"
}
</script>

<style scoped>

</style>
